import React, { useEffect } from 'react'

export default function NotFoundPage() {
  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#EDEDED");
    }

    document.body.style.backgroundColor = '#EDEDED'; // Light grey for ContactPage

    return () => {
      document.body.style.backgroundColor = ''; // Reset to default on unmount
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", "#ADEE68");
      }
    };
  }, []);
  return (
    <div>
      <div className='w-full h-screen justify-center items-center flex flex-col space-y-0'>
        <div className='text-6xl font-semibold py-0 '>404</div>
        <div className='text-xl font-semibold py-3'>Page Not Found</div>

      </div>
    </div>
  )
}
