import React from 'react'

export default function ActiveBoxLayer({isActive}) {
  return (
    <div className={`-z-10 absolute w-full h-full rounded-md -bottom-1.5 -right-1 bg-[#ADEE68] ${
        isActive ? "block " : "hidden"
      }`}>
      </div>
  )
}
