import PoweredByComponent from "./PoweredByComponent";

export default function PayBoxComponent({
  method,
  amount,
  phoneValue,
  handleChange,
  handleSubmit,
  phoneErr,
  status,
}) {
  return (
    <>
      <div className="flex w-[100%]    h-full flex-col bg-[#FFFFFF]  items-center justify-center space-y-3 sm:space-y-6 py-6   sm:h-96  rounded-lg border shadow-md">
        <div className="text-lg  font-bold self-center pb-6 border-b w-[70%] text-center">
          Pay With {method}
        </div>

        <form
          onSubmit={handleSubmit}
          className="self-center w-[80%]  mx-3 flex justify-center  items-center  flex-col    gap-y-10"
        >
          <div className="flex w-full  flex-col  gap-2">
            <div className="text-sm font-bold">Phone Number</div>
            <div className="text-sm font-light">
              Enter your phone number to process the payment
            </div>
            <div className="w-full h-20">
              <input
                type="number"
                value={phoneValue}
                
                onChange={handleChange}
                className="rounded-md text-2xl font-semibold  border pl-3 w-full border-[#144618] h-16 sm:w-full"
                placeholder="09 /07"
              />
              <p className="text-xs w-full font-light text-red-400 ">
                {phoneErr}
              </p>
            </div>
          </div>

          <div className="text-[#ADEE68] font-bold w-full  flex justify-center ">
            <button
              disabled={status?.loading}
              type="submit"
              className={`h-12 ${
                status?.loading ? "cursor-progress" : ""
              } w-full relative rounded-md bg-[#144618] px-5  ${
                !amount ? "bg-[#d3faaa]   animate-pulse" : ""
              }
              
              ${status.loading?"":"sm:pl-[15%]"}`}
            >
              {status.loading ? (
               <div className="w-full text-center">
                 Processing ...

               </div>
              ) : (
                <div className="flex flex-row items-center justify-start space-x-10">
                  <div
                    className={` border-r pr-4 border-[#ADEE68] flex min-w-24 justify-start items-end space-x-0 `}
                  >
                    <div className="text-2xl w-full h-full font-black ">
                      {amount}
                    </div>
                    <div className="text-xs font-black mb-1">
                      {amount ? " ETB" : ""}
                    </div>
                  </div>
                  <div className="ml-10">Pay Now</div>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="w-full block sm:hidden py-6 sm:py-0">
        <PoweredByComponent />
      </div>
    </>
  );
}
