import React, { useEffect } from 'react';
import LinkExpired from '../components/LinkExpired';

export default function LinkExpiredPage() {
  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#EDEDED");
    }

    document.body.style.backgroundColor = '#EDEDED'; // Light grey for ContactPage

    return () => {
      document.body.style.backgroundColor = ''; // Reset to default on unmount
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", "#ADEE68");
      }
    };
  }, []);
  return (
<div>
  
<LinkExpired  />

</div>

    );
}
