import React from 'react'

export default function CardBoxSkeleton() {
  return (
    <div
    className={`rounded-md cursor-pointer border-[#000000] border-opacity-25     flex justify-center items-center w-20 h-20 sm:w-16 sm:h-16 md:w-24 md:h-24  border  bg-white animate-pulse`}>

  </div>
  )
}
