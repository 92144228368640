import React from "react";
import CardComponent from "./CardComponent";
import { STORAGE_URL } from "../constants/constant";
import CardBoxSkeleton from "./CardBoxSkeleton";

export default function WalletComponent({
  activeMethod,
  handleChangeMethod,
  wallets,
}) {
  const walletlists = wallets?.map((wallet) => (
    <CardComponent
      key={wallet.id}
      logo={STORAGE_URL + wallet.logo}
      activeMethod={activeMethod}
      method={wallet.name}
      name={wallet.display_name}
      handleChangeMethod={handleChangeMethod}
    />
  ));

  const cardSkeletons = Array.from({ length: 4 }).map((_, index) => (
    <CardBoxSkeleton key={index} />
  ));

  return (
    <div className=" w-full   max-w-[500px] md:max-w-auto  place-items-center	grid grid-cols-4 	 gap-4 ">
      {wallets ? walletlists : cardSkeletons}
    </div>
  );
}
