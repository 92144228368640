import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import PaidComponent from '../components/PaidComponent';
import { BASE_URL } from '../constants/constant';
import axios from 'axios';

export default function PaidPage() {
  const { transaction_hash } = useParams();
  const [txnDetail, setTxnDetail] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const txn_url = `${BASE_URL}success/txn_detail/${transaction_hash}`;

      try {
        const txnResponse = await axios.get(txn_url);
        setTxnDetail(txnResponse?.data?.data);
      } catch (error) {
        navigate("/pay/not-found");
      }
    };

    if (!txnDetail) {
      fetchData();
    }
    const timer = setTimeout(() => {
      if (txnDetail?.return_url) {
        window.location.href = txnDetail.return_url;
      }
    }, 10000); // 10 seconds

    return () => clearTimeout(timer);
  }, [txnDetail, navigate, transaction_hash]);
  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#144618");
    }

    document.body.style.backgroundColor = '#144618'; // Light grey for ContactPage

    return () => {
      document.body.style.backgroundColor = ''; // Reset to default on unmount
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", "#ADEE68");
      }
    };
  }, []);
  return (
    <PaidComponent txnDetail={txnDetail}/>
  )
}
