import React from 'react'
import ActiveBoxLayer from './ActiveBoxLayer'

export default function CardComponent({logo,activeMethod,method,name,handleChangeMethod}) {
  return (
    <div
    className={`rounded-md cursor-pointer    relative  flex justify-center items-center w-20 h-20 sm:w-16 sm:h-16 md:w-24 md:h-24  border   bg-white ${
        activeMethod === method ? "border-[#BFBFBF] " : " border-[#000000] border-opacity-25"
        
    }`}
    onClick={() => handleChangeMethod(method,name)}

  >
   <ActiveBoxLayer isActive={activeMethod===method}  />
    <img src={logo} alt={`${method}`} className="w-full p-4 h-full  object-contain" />
  </div>
  )
}
