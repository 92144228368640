import React, { useState } from "react";
import PoweredByComponent from "./PoweredByComponent";
import { STORAGE_URL } from "../constants/constant";

export default function LeftSideComponent({
  amount,
  business_logo,
  business_name,
  backToBusiness,
  description,
  goBack,
  title,
}) {
  const [collapsed, setCollapse] = useState(true);

  const handleCollapse = () => {
    setCollapse(!collapsed);
  };

  return (
    <div className="flex  w-full flex-col max-w-lg  px-3    justify-center items-center   ">
      <div className="flex w-full py-3 sm:py-0  sm:space-y-6  border-b border-[#144618] border-opacity-25  sm:border-none flex-row sm:flex-col justify-between sm:justify-center items-center sm:items-start ">
        <div className="flex sm:space-y-6 space-x-2 sm:space-x-0 sm:w-full  h-full flex-row sm:flex-col justify-between sm:justify-start sm:items-start items-center  sm:space-x-0 ">
          <div
            className="cursor-pointer  font-light flex items-center justify-center   sm:h-12"
            onClick={goBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
             
              className="stroke-1  w-6 h-6 sm:w-7 sm:h-7"
              viewBox="0 0 14 14"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 7H.5M4 3.5L.5 7L4 10.5"
              />
            </svg>
          </div>

          <div className="flex flex-row space-x-2  sm:h-20  items-center justify-center ">
            <div
              className={`rounded-full w-8 h-8 ml-3 sm:ml-0 sm:w-16 sm:h-16 overflow-hidden   ${
                !business_logo ? "bg-[#d3faaa]  animate-pulse" : "bg-white"
              }`}
            >
              {business_logo && (
                <img
                  src={STORAGE_URL + business_logo}
                  className=" object-contain	 w-full h-full p-1 "
                  alt={business_name}
                />
              )}
            </div>

            <div
              className={`text-md font-bold  min-w-24  ${
                !business_name
                  ? "h-6 bg-[#d3faaa] rounded-md  animate-pulse"
                  : ""
              }`}
            >
              {business_name}
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-1  items-start justify-center   sm:h-24 ">
          <div className="flex  flex-row items-center justify-start space-x-1 ">
            <div className="text-lg   font-semibold ">Pay </div>
            <div
              className={`text-lg   font-light min-w-24 ${
                !business_name
                  ? "h-6 bg-[#d3faaa] rounded-md  animate-pulse"
                  : ""
              }`}
            >
              {business_name}
            </div>
          </div>
          <div
            className={`text-2xl font-black hidden sm:block ${
              !amount ? "h-8  bg-[#d3faaa] rounded-md  animate-pulse" : ""
            }`}
          >
            {amount} {amount ? " ETB" : ""}
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col items-center justify-center sm:py-6  sm:h-96 space-y-6 ">
        <div className="flex w-full flex-col items-start justify-center gap-2 ">
          <div className="flex w-full min-h-16 flex-row justify-between items-center ">
            <div className="flex flex-col items-between justify- space-y-1 pt-2 ">
              <div className="text-md   font-light sm:font-bold">
                Product/Service Description
              </div>
              <div className="text-md h-6  font-bold sm:hidden">{title}</div>
            </div>
            <div
              className="sm:hidden p-2 rounded-full bg-[#144618] cursor-pointer bg-opacity-10"
              onClick={() => handleCollapse()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`w-6 h-6 transition ease-in-out delay-150 duration-150 ${
                  collapsed ? "rotate-180" : " "
                }`}
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="m2.5 15.25l7.5-7.5l7.5 7.5l1.5-1.5l-9-9l-9 9z"
                />
              </svg>
            </div>
          </div>

          <div
            className={`max-w-sm w-full sm:min-h-80 pb-3 sm:py-10 duration-150 ${
              collapsed ? "max-h-0 " : "max-h-80"
            } overflow-hidden sm:overflow-y-scroll transition-max-height sm:max-h-80 ${
              title ? "sm:bg-[#144618] sm:bg-opacity-5" : "sm:bg-[#d3faaa] sm:animate-pulse"
            } px-6 rounded-lg `}
          >
            <div className="flex py-3 flex-col text-sm h-full justify-center font-bold space-y-3">
              <div className="hidden sm:block">{title}</div>
              <div className="px-1 sm:px-2 md:px-3 lg:px-4 font-light sm:font-bold">{description}</div>
            </div>
          </div>
        </div>
        <div className="w-full hidden sm:block sm:pt-6 ">
          <PoweredByComponent />
        </div>
      </div>
    </div>
  );
}
