import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CheckoutPage from "./pages/CheckoutPage";
import SuccessfullPage from "./pages/SuccessfullPage";
import NotFoundPage from "./pages/NotFoundPage";
import PaidPage from "./pages/PaidPage";
import LinkExpiredPage from "./pages/LinkExpiredPage";
const router = createBrowserRouter([
  {
    path: "/pay/initiate/:transaction_hash",
    element: <CheckoutPage />,
  },
  {
    path: "/pay/link-expired",
    element: <LinkExpiredPage />,
  },
  {
    path: "/pay/paid/:transaction_hash",
    element: <PaidPage />,
  },
  {
    path: "/pay/success/:transaction_hash",
    element: <SuccessfullPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
