import React, { useEffect, useState } from "react";
import PoweredByComponent from "./PoweredByComponent";

export default function LinkExpired() {
  const [returnUrl, setReturnUrl] = useState(null);
  useEffect(() => {
    const url = localStorage.getItem("return_url");
    if (url) {
      setReturnUrl(url);
    }
  }, []);
  const backToBusiness = () => {
    window.location.href = returnUrl;
  };
  return (
    <div className="w-full flex justify-center items-center  min-h-screen ">
      <div className="w-[90%]  max-w-md  text-[#D16464] flex flex-col justify-center space-y-10  ">
        <div className="text-center text-xl font-bold  mb-6 ">
          Payment Link Expired
        </div>

        <div
          className={`w-full  bg-white  min-h-[30rem] pt-6 pb-12 rounded-md flex justify-center items-between relative  text-sm font-bold`}
        >
          <div className="w-[90%] sm:w-[80%] bg-white  flex justify-center flex-col items-center space-y-5 ">
            <div className="w-20  h-20 flex absolute -top-8  justify-center bg-white border-4 border-[#EDEDED] rounded-full">
              <svg
                className="w-full h-full"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8L4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>

            <div className="w-full  text-center border-b py-3 border-[#000000]/10 ">
              Your Payment Link has been expired
            </div>

            <div className= {`h-full w-full flex justify-center items-center space-y-3 pb-6 ${returnUrl?"border-b border-[#000000]/10":""}`} >
              <div className="w-[70%] text-center">
                Your payment link has <br />
                already expired, please return to the
                <br />
                merchant and initiate a new payment.
              </div>
            </div>

            <div className="w-full">
              {returnUrl && 
              <button
              type="submit"
              onClick={backToBusiness}
              className={`h-12 w-full relative rounded-md bg-[#D16464] px-5 text-sm font-bold  text-[#FFFFFF]`}
            >
              Return to Merchant
            </button>}
              
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center text-[#636363]">
          <PoweredByComponent />
        </div>
      </div>
    </div>
  );
}
