import React from 'react'

export default function PoweredByComponent() {
  return (
    <div className="flex flex-row  w-full sm:w-[70%] space-x-1 justify-center items-center">
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M10.95 14.858L15.908 9.9l-.714-.713l-4.244 4.244l-2.138-2.139l-.714.714l2.852 2.852ZM12 20.962q-3.013-.895-5.007-3.651Q5 14.554 5 11.1V5.692l7-2.615l7 2.615V11.1q0 3.454-1.993 6.21q-1.994 2.757-5.007 3.652Z"
        />
      </svg>
    </div>
    <div className="text-sm font-light">powered by</div>
    <div className="text-sm font-semibold">Checkout.et</div>
  </div>
  )
}
