import React from "react";

export default function TabComponent({ tab, handleTabChange }) {
  
  return (
    <>
    
      <div className="bg-[#144618] mx-auto relative w-full max-w-sm   self-center p-1 rounded-[25px] h-12 text-sm  font-semibold flex flex-row justify-center space-x-2 items-center">
      <div className={`absolute w-1/2 transition delay-100 duration-300 left-0 bottom-0 top-0 p-1 z-10`} style={{
                    transform: `translateX(${((tab - 1) * 100)}%)`,
                  }}>

            <div className="w-full h-full text-[#144618] bg-[#ADEE68] rounded-[25px]">

            </div>
          </div>




        <button
          onClick={() => handleTabChange(1)}
          className={` ${
            tab === 1 ? "text-[#144618]" : "text-[#ADEE68]"
          } h-full  w-1/2 text-center z-20 transition delay-100 duration-300`}
        >
          Wallet
        </button>
        <button
          onClick={() => handleTabChange(2)}
          className={`  ${
            tab === 2 ? "text-[#144618]" : "text-[#ADEE68]"
          } h-full w-1/2  text-center z-20 transition delay-100 duration-300`}
        >
          Bank Card
        </button>
      </div>
    </>
  );
}
