import React, { useEffect, useState } from "react";
import { BASE_URL } from "../constants/constant";
import axios from "axios";
import { useNavigate } from "react-router";

export default function UssdTimerComponent({
  onClose,
  activeMethodName,
  transaction_hash,
  return_url,
}) {
  const [time, setTime] = useState(300);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const check_timeline = BASE_URL + `latest_timeline/${transaction_hash}`;

    const fetchData = async () => {
      try {
        await axios.get(check_timeline);
        setLoading(true);
        navigate(`/pay/paid/${transaction_hash}`);
      } catch (err) {
        if (err.response && err.response.status === 424) {
          setError(err.response?.data?.message);
        }
        // If the request fails, you can optionally retry after a delay.
        // setTimeout(fetchData, 1000);
      } finally {
        setTimeout(fetchData, 1000); // Wait for 1 second before the next request
      }
    };

    fetchData(); // Initial call to fetchData

    return () => clearTimeout(fetchData); // Clean up the timeout if the component unmounts
  }, [error, setError, loading, setLoading, navigate, transaction_hash]);

  useEffect(() => {
    if (time === 0) {
      onClose();
      return;
    }

    const intervalId = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [time, setTime, onClose]);

  // Calculate minutes and seconds
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const title = error
    ? "Your payment has failed "
    : `Kindly input your ${activeMethodName} PIN on your phone upon
  receiving the prompt.`;
  return (
    <div className="absolute  w-full h-full bg-gray-800 opacity-95 z-50 flex justify-center items-center ">
      <div
        id="timerBox"
        className="bg-white opacity-100 relative flex flex-col justify-center items-center  w-[80%] max-w-sm p-10 h-80 shadow-md rounded-md "
      >
        <div
          className="-right-7 -top-7 cursor-pointer absolute bg-white rounded-full border-2 border-black"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 72 72"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="2"
              d="m17.5 17.5l37 37m0-37l-37 37"
            />
          </svg>
        </div>
        {loading ? (
          <div>Loading ...</div>
        ) : (
          <>
            {error && (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#D16464"
                    d="m9.4 13.288l2.6-2.58l2.6 2.58l.688-.688l-2.58-2.6l2.58-2.6l-.688-.688l-2.6 2.58l-2.6-2.58l-.688.688l2.58 2.6l-2.58 2.6l.688.688ZM3 20.077V3h18v14H6.077L3 20.077ZM5.65 16H20V4H4v13.644L5.65 16ZM4 16V4v12Z"
                  />
                </svg>
              </div>
            )}
            <div className="font-bold">
              <p>{title}</p>
            </div>

            <div className="w-full h-full flex justify-center items-center">
              {error ? (
                <div className="text-red-600 text-sm ">
                  Please check your balance or PIN
                </div>
              ) : (
                <div className="flex w-32 items-center justify-center space-x-2  py-2 px-5 bg-red-400 rounded-md text-white font-medium text-2xl">
                  <div className="h-full  ">
                    {minutes < 10 ? `0${minutes}` : minutes}
                  </div>
                  <div>:</div>
                  <div className="h-full ">
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {/* <div>
          <button
          onClick={backToBusiness}
            className={`h-12 w-[80%] absolute bottom-2 rounded-md bg-[#144618] px-5 text-sm font-bold  text-[#ADEE68]`}
          >
            Back To Merchant 
          </button>
        </div> */}
      </div>
    </div>
  );
}
