import React from "react";
import PoweredByComponent from "./PoweredByComponent";
import numeral from "numeral";

export default function PaymentSuccess({ txnDetail }) {
  const formatAmount = (amount) => {
    return numeral(amount).format("0,0");
  };

  const backToBusiness = () => {
    window.location.href = txnDetail?.return_url;
  };
  return (
    <div className="w-full flex justify-center items-center  min-h-screen ">
      <div className="w-[90%]  max-w-md flex flex-col justify-center space-y-10  ">
        <div className="text-center text-xl font-bold text-[#ADEE68] mb-6 ">
          Payment Successfull
        </div>

        <div
          className={`w-full  min-h-[30rem]   ${
            !txnDetail ? "bg-[#d3faaa]  animate-pulse" : "bg-[#ADEE68]"
          } min-h-fit pt-6 pb-12 rounded-md flex justify-center items-center relative text-[#144618] text-sm font-bold`}
        >
          <div className="w-[90%] sm:w-[80%] flex justify-center flex-col items-center space-y-5 ">
            <div className="w-20  h-20 flex absolute -top-8  justify-center bg-[#ADEE68] border-4 border-[#144618] rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 21"
                className="w-full h-full"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m5.5 11.5l3 3l8.028-8"
                />
              </svg>
            </div>

            <div className="w-full  text-center border-b py-3 border-[#000000]/10 ">
              Your Payment has been completed
            </div>
            <div className="flex flex-col items-center space-y-1">
              <div className="text-sm font-semibold">Paid Amount</div>
              <div className="text-3xl font-bold">
                {txnDetail
                  ? formatAmount(txnDetail?.amount) + " ETB"
                  : "0000 ETB"}
              </div>
            </div>

            <div className="h-full w-full flex flex-col space-y-3 pb-6 border-b border-[#000000]/10">
              <div className="w-full flex justify-between items-center pt-5">
                <div>Paid to</div>
                <div>{txnDetail?.business?.name}</div>
              </div>
              <div className="w-full flex justify-between items-center">
                <div>Paid By</div>
                <div>{txnDetail?.customer?.phone}</div>
              </div>
              <div className="w-full flex justify-between items-center">
                <div>Payment Method</div>
                <div>{txnDetail?.payment_gateway?.display_name}</div>
              </div>
              <div className="w-full flex justify-between items-center">
                <div>Payment Date</div>
                <div>{txnDetail?.payment_date}</div>
              </div>
              <div className="w-full flex justify-between items-center">
                <div>Processor Ref</div>
                <div>{txnDetail?.processor_reference_no}</div>
              </div>
              <div className="w-full flex justify-between items-center">
                <div>Ref</div>
                <div>{txnDetail?.reference_no}</div>
              </div>
            </div>

            <div className="w-full">
              <button
                type="submit"
                onClick={backToBusiness}
                className={`h-12 w-full relative rounded-md bg-[#144618] px-5 text-sm font-bold  text-[#ADEE68]`}
              >
                Return to Merchant
              </button>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center text-[#ADEE68]">
          <PoweredByComponent />
        </div>
      </div>
    </div>
  );
}
