import React, { useEffect, useRef, useState } from "react";
import TabComponent from "../components/TabComponent";
import WalletComponent from "../components/WalletComponent";
import BankCardComponent from "../components/BankCardComponent";
import PayBoxComponent from "../components/PayBoxComponent";
import LeftSideComponent from "../components/LeftSideComponent";
import { useNavigate, useParams } from "react-router";
import { BASE_URL } from "../constants/constant";
import axios from "axios";
import numeral from "numeral";
import UssdTimerComponent from "../components/UssdTimerComponent";

export default function CheckoutPage() {
  const [tab, setTab] = useState(1);
  const [activeMethod, setActiveMethod] = useState(null);
  const [activeMethodName, setActiveMethodName] = useState(null);
  const { transaction_hash } = useParams();
  const [txnDetail, setTxnDetail] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [error, setError] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [status, setStatus] = useState({ loading: false });
  const navigate = useNavigate();
  const [ussdSent,setUssdSent]=useState(false);




  const boxRef = useRef(null);

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      console.log('Body clicked, not inside the box');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  const goBack = () => {
    window.location.href = txnDetail?.return_url;
  };
  const backToBusiness = () => {
    window.location.href = txnDetail?.business?.website;
  };

  const formatAmount = (amount) => {
    return numeral(amount).format("0,0");
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);

    validatePhoneNumber(value);
  };


  const onClose=()=>{
    setUssdSent(false)
  }
  const handleSubmit = (e) => {
    setStatus({ ...status, loading: true });
    e.preventDefault();
    if (validatePhoneNumber(phoneNumber) && activeMethod) {
      let data = {
        phone: phoneNumber,
        method_name: activeMethod,
        transaction_hash: transaction_hash,
      };
      const pay_url = BASE_URL + "pay";
      axios
        .post(pay_url, data)
        .then((response) => {
          console.log('response '+response);
          if (response?.data && response?.data?.payurl) {
            let url = response?.data?.payurl;
            window.location.href = url;
          }else if(response?.data && response?.data?.ussd) {

            setUssdSent(true)

          } else {
            console.error("Unexpected response format:", response);
            setError("Unexpected response format");
          }
        })
        .catch((error) => {
          setError("Error:", error);

          console.error("Error:", error);
        })
        .finally(() => {
          setStatus({ ...status, loading: false });

          // Update loading status
          // requestStatus.loading = false;
        });
    } else {
      setStatus({ ...status, loading: false });
    }
  };
  const validatePhoneNumber = (phone) => {
    let phoneRegex;

    // Determine phone number regex based on payment method
    if (activeMethod ==="M_PESA") {
      // Allow numbers starting with 07, 2517, or +2517
      phoneRegex = /^(07|2517|\+2517)\d{8}$/;
    } else if (activeMethod === "TELEBIRR") {
      // Allow numbers starting with 09 or +2519
      phoneRegex = /^(09|2519|\+2519)\d{8}$/;
    } else {
      // Default regex for other payment methods (e.g., A payment method)
     phoneRegex = /^(09|2519|\+2519|07|2517|\+2517)\d{8}$/;
    }
    if (phone === "") {
      setPhoneErr("phone number is required");
      return false; // Return false if phone number is empty
    } else if (!phoneRegex.test(phone)) {
      setPhoneErr("Please enter a valid phone number");
      return false; // Return false if phone number does not match regex
    } else {
      setPhoneNumber(phone);
      setPhoneErr("");
      return true; // Return true if phone number is valid
    }
  };

  const handleTabChange = (tabValue) => {
    setTab(tabValue);
  };

  const handleChangeMethod = (methodValue, methodName) => {
    setActiveMethod(methodValue);
    setActiveMethodName(methodName);
  };

  useEffect(() => {
    const fetchData = async () => {
      const txn_url = BASE_URL + `txn_detail/${transaction_hash}`;

      const payment_method_url = BASE_URL + "methods";

      axios
        .get(txn_url)
        .then((txnResponse) => {
          if (txnResponse?.status === 201) {
            navigate(`/pay/paid/${transaction_hash}`);
          }
          setPhoneNumber(txnResponse?.data?.data?.customer?.phone);
          setTxnDetail(txnResponse?.data?.data);

          axios
            .get(payment_method_url)
            .then((methodResponse) => {
              setActiveMethod(methodResponse?.data?.WALLET[0].name);
              setActiveMethodName(methodResponse?.data?.WALLET[0].display_name);
              setPaymentMethods(methodResponse?.data);
            })
            .catch(() => {
              navigate("/pay/not-found");

            });
        })
        .catch((error) => {
          console.log(error)
          if (error?.response?.status === 401) {
            const return_url = error.response?.data?.return_url;
            localStorage.setItem("return_url", return_url);

            navigate("/pay/link-expired");
          } else {
            navigate("/pay/not-found");
          }
        });

     
    };

    fetchData();
  }, [navigate, transaction_hash]);


  return (
    <div className="flex flex-col sm:flex-row   mx-auto  relative  ">
      {ussdSent && (
        <UssdTimerComponent onClose={onClose} backToBusiness={backToBusiness} activeMethodName={activeMethodName} transaction_hash={transaction_hash} return_url={txnDetail?.return_url}/>
      )}
      <div className="w-full  sm:w-1/2 flex justify-center  bg-[#ADEE68]  sm:min-h-screen   ">
        <div className="w-full sm:pb-6  flex justify-center items-center">
          <LeftSideComponent
            amount={formatAmount(txnDetail?.amount)}
            business_logo={txnDetail?.business?.logo}
            business_name={txnDetail?.business?.name}
            description={txnDetail?.description}
            title={txnDetail?.title}
            goBack={goBack}
            backToBusiness={backToBusiness}
          />
        </div>
      </div>
      {error && error?.data && error?.data?.message ? (
        <div className="absolute top-1 right-1 z-20 text-white bg-red-300 py-2 rounded-md px-3 ">
          {error?.data?.message}
        </div>
      ) : null}
      {/* sm:pr-5 md:pr-10 lg:pr-20 xl:pr-30 2xl:pr-40 */}
      <div className="w-full  sm:px-0  flex  z-20 sm:w-1/2 justify-center    sm:min-h-screen   ">
        <div className="w-full sm:max-w-xl	 flex justify-center  items-center">
          <div className="flex w-full space-y-4 sm:space-y-6  px-3   flex-col  justify-center     ">
            <div className="space-y-2 sm:space-y-6 w-full  flex flex-col   items-center">
              <div className="text-md font-bold flex items-center justify-center mt-4 sm:mt-0   text-center text-[#144618]  sm:h-12">
                Choose Payment Methods
              </div>
              <div className="w-full flex items-center justify-center  sm:h-20 ">
                <TabComponent tab={tab} handleTabChange={handleTabChange} />
              </div>
              <div className="w-full flex items-center justify-center  h-24">
                {tab === 1 ? (
                  <WalletComponent
                    activeMethod={activeMethod}
                    handleChangeMethod={handleChangeMethod}
                    wallets={paymentMethods?.WALLET}
                  />
                ) : (
                  <BankCardComponent
                    activeMethod={activeMethod}
                    handleChangeMethod={handleChangeMethod}
                    cards={paymentMethods?.BANK_CARD}
                  />
                )}
              </div>
            </div>
            <div>
              <PayBoxComponent
                method={activeMethodName}
                amount={formatAmount(txnDetail?.amount)}
                phoneValue={phoneNumber}
                handleSubmit={handleSubmit}
                phoneErr={phoneErr}
                handleChange={handleChange}
                status={status}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
