import React from "react";
import CardComponent from "./CardComponent";
import { STORAGE_URL } from "../constants/constant";
import CardBoxSkeleton from "./CardBoxSkeleton";

export default function BankCardComponent({ activeMethod, handleChangeMethod, cards }) {
  // Check if cards is undefined, and provide a default empty array if it is
  const cardLists = (cards ?? []).map((card) => (
    <CardComponent
      key={card.id}
      logo={STORAGE_URL + card.logo}
      activeMethod={activeMethod}
      method={card.name}
      name={card.display_name}
      handleChangeMethod={handleChangeMethod}
    />
  ));

  const cardSkeletons = Array.from({ length: 4 }).map((_, index) => (
    <CardBoxSkeleton key={index} />
  ));

  return (
    <div className=" w-full   max-w-[500px] md:max-w-auto  place-items-center	grid grid-cols-4 	 gap-4 ">
      {cardLists.length > 0 ? cardLists : cardSkeletons}
    </div>
  );
}
